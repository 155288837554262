window.loaded = false;
var windowWidth = 0;

$(window).on('load', function(){
	window.loaded = true;
});

var homeSlider = undefined;
let homeImageSlider = undefined;


$(document).ready(function() {
	/* MOBILE */
	//create js hook links
	windowWidth = window.innerWidth;
	var resizeFn = function() {
		if (windowWidth != window.innerWidth) {
			windowWidth = window.innerWidth;

			$(document).trigger('campusnet.window_width_changed', windowWidth);
		}
	}

	window.addEventListener('resize', resizeFn);

	$('.mailtolink').click(function(e) {
		handleMailtoLink($(this));
	});

	$('[data-purpose="navigation-link"]').each(function(){
		setNavigationUrl($(this));
	});
	
	$('[data-purpose="remodal-open"]').click(function(){
		var target = $(this).data('popup-target');
		if ($(this).data('popup-target')) {
			var item = $('[data-remodal-id="' + $(this).data('popup-target') + '"]');
			if (item.length > 0) {
				item.remodal().open();
			}
		}
	});
	
	if( $('.cookie-bar').length > 0 ) {

		$('.cookie-bar .button').click(function(){
			$('.cookie-bar').addClass('closed');

			$.ajax({
				url:ajax_controller+'setCookieConfirm',
				dataType:'json',
				type:'post',
				data:{cookie:1},
				success: function(){}
			});
		});
	}

	$('[data-purpose="remodal-close"]').click(function(){
		var value = $(this).data('value');
		if (value && value == 'confirm') {
			$('#terms-chk').attr('checked', true);
		}

		$(this).parents('.remodal').remodal().close();
	});

	$('[data-purpose="scroll-to-element"]').click(function(){
		var target = $(this).data('target');
		var item = $(target);
		var timeout = $(this).data('timeout');
		
		if (isNaN(timeout)) {
			timeout = 400;
		}
		
		scrollToElement(item, timeout);
	});

	$('[data-purpose="remodal-open"]').click(function(){
		var target = $(this).data('target');
		if (target) {
			openRemodalContent(target);
		}
	});

	$('[data-purpose="privacy-confirm-button"]').click(function(){
		var type = $(this).data('type');
		$('#privacyChk').prop('checked', type == 'accept');
		$('[data-remodal-id="privacyRemodal"]').remodal().close();
	});


	$('select').change(function(){
		var value = parseInt($(this).val());
		
		if (!value && $(this).hasClass('has-value')) {
			$(this).removeClass('has-value');
		} else if (value && !$(this).hasClass('has-value')) {
			$(this).addClass('has-value');
		}
	}).change();
		
	$('.accordion-list').each(function(ind, el) {

		var options = {};
		var th = $(el);

		if (th.data('single-active-item') != undefined) options.singleActiveItem = true;
		if (th.data('active-index') != undefined) options.activeIndex = th.data('active-index');
		if (th.data('from-width') != undefined) options.fromWidth = parseInt(th.data('from-width'));
		
		new Accordion(th, options);
  	});

  	if (typeof articleGalleryItems !== 'undefined') {
		$('.article-gallery-header-item').click(function(){
			openArticleGallery($(this));
		});
	}

	$('[data-purpose="lang-chooser"]').on('change', function(){
		var option = $(this).find('option:selected');
		if (option && option.length === 1) {
			var url = option.data('url');
			if (url) {
				document.location.href = url;
			}
		}
	});

	initGalleries();
	initTooltipster();

	if (site_id == 1) {
		$('.mobile-menu-content .group-title').on('click', function(e) {
			var target = $(this).data('target');
			
			if (target) {
				e.preventDefault();

				scrollToElement($(target));
				$(document).trigger('campusnet.close_mobile_menu');
			}
		});

		// swiper inicializálás
		//initHomeHeaderSlider()
	}
});

function initHomeHeaderSlider()
{	
	if ($('#homeImageSlider').length === 1) {

		if ($('#homeImageSlider').find('.swiper-slide').length <= 1) {
			// return;
		}

		var options = {
			effect: 'slide',
			height: 'auto',
			speed: 1500,
			autoHeight:true,
			pagination: {
			  el:'.swiper-pagination',
			  clickable: true
			},
		};

		const delay = $('#homeImageSlider').data('delay');
        if (!isNaN(parseInt(delay))) {
            options.autoplay = {
                delay: parseInt(delay)
            };
        }

		homeImageSlider = new Swiper('#homeImageSlider', options);

		// $(document).on('campusnet.window_width_changed', function() {
        //     homeImageSlider.updateAutoHeight(0);
        // });
	}
}

function scrollToElement(item, timeout) 
{
	if (!timeout) {
		timeout = 400;
	}

	if (item.length > 0) {
		$('html, body').animate({scrollTop: item.offset().top}, timeout);
	}
}

function openRemodalContent(remodal_id) {
	var tmp = $('[data-remodal-id="'+remodal_id+'"]');
	if(tmp.length > 0){
	  tmp.remodal().open();
	}
}

function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function sendAnalyticsEvent(action, category, tag) {
	if (typeof gtag !== 'undefined' && action && category) {
		var evtData = {
			event_category: category,
		};

		if (tag && tag.trim() != '') {
			evtData.event_label = tag;
		}
		
		gtag('event', action, evtData);
	} else if(typeof ga != 'undefined' && action && category){
		if(tag && tag.trim() != ''){
			ga('send', 'event', action, category, tag);
		}else{
			ga('send', 'event', action, category);
		}
	}
}

function svg(id) {
	return '<span class="svg"><svg class="icon ' + id + '"><use xlink:href="#' + id + '" /></svg></span>';
}


function handleMailtoLink(th) {
	if (th.attr('href') == 'javascript:void(0)') {
		var hidden = th.children('.emailhidden');
		th.attr('href', 'mailto:' + hidden.data('user') + '@' + hidden.data('domain'));
	}
}


function loadJs(url, implementationCode){
    var scriptTag = document.createElement('script');
    scriptTag.src = url;

    scriptTag.onload = implementationCode;
    scriptTag.onreadystatechange = implementationCode;

    document.body.appendChild(scriptTag);
};

function setNavigationUrl(item) {
	var address = item.data('address');
	
	if (address) {
		var itemProtocol = item.data('protocol');
		var protocol = itemProtocol ? itemProtocol : 'google';
		var ua = navigator.userAgent.toLowerCase();
		var plat = navigator.platform;
		var device = ua.match(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera/i) ? ua.match(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera/i)[0] : false;

		if(!itemProtocol && (device == 'iphone' || device == 'ipad' || device == 'ipod')) {
			function iOSversion() {
					
				if (/iP(hone|od|ad)/.test(navigator.platform)) {
				  var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
				  return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
				}
			}

			var ver = iOSversion() || [0];
			if (ver[0] >= 6) {
				protocol = 'apple';
			}
		}
		
		var urlSuffix = '/?daddr='+address;
		var nav_url = '';
		switch(protocol) {
			case 'apple':
				nav_url = 'maps://';
				break;
			default:
				nav_url = 'https://maps.google.com/maps';
				break;
		}
		item.attr('target', '_blank');
		item.attr('href', nav_url+urlSuffix);
	}
}


function openGallery(item) {
	var groupId = item.data('group-id');
	var index = item.data('index');
	
	if (typeof galleryItems === 'object' && typeof galleryItems[groupId] !== 'undefined') {
		if (!index) {
			index = 0;
		}

		initGallery(item, galleryItems[groupId], index);
	} 
}

function openArticleGallery(item) {
	var groupId = $(item).data('gallery-id');
	if (groupId && typeof articleGalleryItems === 'object' && typeof articleGalleryItems[groupId] !== 'undefined') {
		initGallery(item, articleGalleryItems[groupId]);
	}
}

function initGallery(target, items, index) 
{
	if (!index) {
		index = 0;
	}

	$(target).lightGallery({
		dynamic:true,
		dynamicEl:items,
		index:index,
		hash:false,
		download: false
	});
}

function initGalleries()
{
	if (typeof mediaGroupItems === 'object' && Object.keys(mediaGroupItems).length > 0) {
		$('[data-purpose="gallery-box-item"]:not(.initialized)').each(function(){
			$(this).addClass('initialized');
			$(this).click(function(){
				var id = $(this).data('group-id');
				if (id && typeof mediaGroupItems[id] !== 'undefined') {
					var index = $(this).data('index');
					initGallery($(this), mediaGroupItems[id], index);
				}
			});
		});
	}
}

function initTooltipster()
{
	$.fn.tooltipster('setDefaults', {
		position: 'right',
		trigger:'click'
	});

	$('.tooltipster-item:not(.initialized)').each(function(){
		$(this).addClass('initialized');
		$(this).tooltipster({
			position:'right',
			contentAsHTML:true,
			trigger:'click',
			functionBefore: function(orig, tooltip) {
				$(tooltip.origin).addClass('opened');
			},
			functionAfter: function(orig, tooltip) {
				$(tooltip.origin).removeClass('opened');
			}
		});
	});
}